@import url("https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@400;500;600&display=swap");

h1 {
    font-family: Mukta Vaani, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #465968;
}

h2 {
    font-family: Mukta Vaani;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #465968;
}

h3 {
    font-family: Mukta Vaani;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #465968;
}

path {
    pointer-events: all;
}

path:hover {
    fill: #00a86b94;
    // opacity: 0.50;
    cursor: pointer;
}

path.AK.state:hover{
    fill: transparent !important;
    cursor: default !important;
}
path.AK.state{
    display: none;
}
path.HI.state{
    display: none;
}
path.HI.state:hover{
    fill: transparent !important;
    cursor: default !important;
}
