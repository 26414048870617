.home-page {
    padding-bottom: 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
        line-height: 1.15em;

        @media (max-width: 768px) {
            font-size: 38px;
        }
    }

    h2 {
        @media (max-width: 450px) {
            font-size: 24px;
        }
    }
}

.back-button {
    margin-bottom: 1rem;
}
