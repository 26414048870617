@import "~antd/dist/antd";

@font-face {
    font-family: 'Futura';
    font-weight: 400;
    src: url('./assets/fonts/futur.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura';
    font-weight: 500;

    
    src: url('./assets/fonts/Futura-Heavy-font.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura';
    font-weight: 700;
    src: url('./assets/fonts/Futura-Extra-Black-font.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
